import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const candidate = `${HOST}job-cadidates`

export default {
  getCandidates(context1, jobId) {
    const context = context1
    const params = {
      params: {
        query: context.searchQuery, page: context.currentPage - 1, user_id: context.employerId, type_id: context.type,
      },
    }
    axios.get(`${candidate}/${jobId}/candidates`, params).then(response => {
      context.setCandidates(response.data)
    }, () => {
    })
  },
  addCandidate(context1, body) {
    const context = context1
    context.$vs.loading()
    axios.post(candidate, body).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },
  updateCandidate(context1, id, body) {
    const context = context1
    context.$vs.loading()
    axios.put(`${candidate}/external/${id}`, body).then(response => {
      context.$vs.loading.close()
      context.candidateUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${candidate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.candidateDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotDeleted(error.response.data.return_message)
    })
  },
  getCandidateById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${candidate}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setCandidate(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotFound(error.response.data.return_message)
    })
  },
  addExistingusers(context1, jobId, typeId, employerId, userIds, Experience, submittedDate) {
    const request = {
      submitteddate: submittedDate, parent_type_id: typeId, parent_id: employerId, user_ids: userIds, experience: Experience,
    }
    const context = context1
    context.$vs.loading()
    axios.post(`${candidate}/${jobId}`, request).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },
  addReferCandidate(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(`${candidate}/refer`, context.addCandidate).then(response => {
      context.$vs.loading.close()
      context.candidateAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.candidateNotAdded(error.response.data.return_message)
    })
  },

}
